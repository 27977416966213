<template>
  <v-row no-gutters align="center" style="margin-top: 30px">
    <div
      class="swipeBtn"
      @click="prevSlide"
      :style="disablePrevIcon ? 'pointer-events: none;' : ''"
    >
      <div
        class="swipeArrow"
        :style="disablePrevIcon ? 'background-color:#b5b5b5' : ''"
      />
    </div>
    <div class="swipeContent">
      <v-slide-group center-active v-model="slide">
        <template v-slot:next>
          <v-icon style="display: none; pointer-events: none"></v-icon>
        </template>
        <v-slide-item v-for="(date, index) in daysList" :key="index">
          <div
            class="slideItem"
            :class="
              new Date(date).toLocaleDateString('uk-UA', {
                weekday: 'short',
                day: 'numeric',
                 timeZone: 'Europe/Kiev'
              }) ==
              new Date(activeDate).toLocaleDateString('uk-UA', {
                weekday: 'short',
                day: 'numeric',
                 timeZone: 'Europe/Kiev'
              })
                ? 'activeSlideItem'
                : new Date(date) < new Date(new Date().getTime() - 86400000)
                ? 'inActiveSlideItem'
                : ''
            "
            @click="setActiveDate(1, date)"
          >
            {{
              new Date(date).toLocaleDateString("uk-UA", {
                weekday: "short",
                day: "numeric",
                 timeZone: 'Europe/Kiev'
              })
            }}
          </div>
        </v-slide-item>
      </v-slide-group>
    </div>
    <div class="swipeBtn" @click="nextSlide">
      <div class="swipeArrow" style="transform: scaleX(-1)" />
    </div>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    daysList: [],
    current_date: new Date(),
    activeDate: new Date(),
    swipeDate: new Date(),
    slide: 0,
    disablePrevIcon: true,
  }),
  mounted() {
    if (this.$route.query !== undefined) {
      this.activeDate = new Date(this.$route.query.departureDate);
      this.setDatesForRange();
    }
  },
  methods: {
    nextSlide() {
      //SWIPE NEW DATE CURRENT DATE + 9 DAY
      if (!this.$vuetify.breakpoint.smAndDown) {
        this.swipeDate = this.addDays(this.swipeDate, 5);
      } else {
        this.swipeDate = this.addDays(this.swipeDate, 9);
      }
      //FORMATTING NEW DATES ARRAY
      if (!this.$vuetify.breakpoint.smAndDown) {
        this.getDates(this.swipeDate, 2);
      } else {
        this.getDates(this.swipeDate, 5);
      }
      //ON DISABLED PREV BTN
      this.disablePrevIcon = false;
    },
    prevSlide() {
      //IF SWIPE DATE 9 DAYS LONGER THAN TODAY
      if (
        new Date(this.swipeDate) > new Date().setDate(new Date().getDate() + 5)
      ) {
        ////SWIPE NEW DATE CURRENT DATE - 9 DAY
        if (this.$vuetify.breakpoint.smAndDown) {
          this.swipeDate = this.takeAwayDays(this.swipeDate, 5);
        } else {
          this.swipeDate = this.takeAwayDays(this.swipeDate, 9);
        }
        //FORMATTING NEW DATES ARRAY
        if (this.$vuetify.breakpoint.smAndDown) {
          this.getDates(this.swipeDate, 2);
        } else {
          this.getDates(this.swipeDate, 5);
        }
      } else {
        //DISABLED PREV ICON SO THAT THE USER CANNOT FLIP THROUGH OLD DATES
        this.disablePrevIcon = true;
      }
    },
    getDates(date, day) {
      let current = new Date(date);
      var week = new Array();
      // Starting Monday not Sunday
      current.setDate(current.getDate() - day);
      // if (this.$vuetify.breakpoint.smAndDown) {
      //   for (let i = 0; i < 5; i++) {
      //     week.push(new Date(current));
      //     current.setDate(current.getDate() + 1);
      //   }
      // } else {
      for (var i = 0; i < 9; i++) {
        week.push(new Date(current));
        current.setDate(current.getDate() + 1);
        //}
      }

      //FORMATTING WEEK DAYS LIST
      this.daysList = week;
    },
    setActiveDate(quantity_days, date) {
      //THE ACTIVE DATE WILL BE EQUAL TO THE DATE CHOSEN BY THE USER
      this.activeDate = date;
      //ADD NUMBER OF DAYS TO CURRENT DATE TO FORMAT VALID LIST
      this.current_date = this.addDays(this.activeDate, quantity_days);
      //ASSIGNING A NEW DATE FOR CORRECT DATE FLIP
      this.swipeDate = this.current_date;
      this.$router.push(
        `/search/${this.$route.params.departure_city}/${
          this.$route.params.arrival_city
        }?departureDate=${new Date(this.activeDate)
          .toISOString()
          .substring(0, 10)}&qty=${this.$route.query.qty}`
      );
      //FORMATTING NEW DATES ARRAY
      if (this.$vuetify.breakpoint.smAndDown) {
        this.getDates(this.activeDate, 2);
      } else {
        this.getDates(this.activeDate, 5);
      }
    },
    setDatesForRange() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.getDates(this.activeDate, 2);
        setTimeout(() => {
          this.menu = false;
        }, 200);
        setTimeout(() => {
          this.showLoader = false;
        }, 400);
      } else {
        this.getDates(this.activeDate, 4);
        setTimeout(() => {
          this.showLoader = false;
        }, 400);
      }
    },
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    takeAwayDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() - days);
      return result;
    },
  },
};
</script>

<style scoped>
.swipeBtn {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 12px 18px;
  width: max-content;
  height: max-content;
  display: grid;
  place-items: center;
  cursor: pointer;
  user-select: none;
}
.swipeBtn:hover {
  background: #144fa9;
}
.searchRouteFieldBackground {
  background: #fcfcfc;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 50px 35px;
  margin-top: 20px;
}
.searchRouteFieldBackgroundMobile {
  border-radius: 10px;
  border: 1px solid #bcbcbc;
  background: #fcfcfc;
  height: 70px;
  padding: 12px;
  width: 100%;
}
@media screen and (max-width: 1023px) {
  .swipeContent {
    background: #fcfcfc;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 140px);
    overflow: hidden;
    height: 56px;
    margin: 0px 11px;
    padding: 20px 0px;
  }
  .slideItem {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #1b1b1b;
    margin-right: 10px;
    padding: 5px;
    user-select: none;
    cursor: pointer;
  }
  .activeSlideItem {
    border: 1.5px solid #182c2e;
    border-radius: 5px;
    width: max-content;
  }
  .inActiveSlideItem {
    color: #b5b5b5;
    pointer-events: none;
  }
  .swipeArrow {
    width: 14px;
    height: 26px;
    background-color: black;
    -webkit-mask: url("../../../assets/img/iconsSvg/swipeArrow.svg") no-repeat
      center;
    mask: url("../../../assets/img/iconsSvg/swipeArrow.svg") no-repeat center;
  }
  .noRouteTitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #1b1b1b;
    margin-bottom: 10px;
    margin-top: 40px;
  }
  .noRouteText {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #989898;
    margin-bottom: 0px;
  }
  .textStyle {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #eeeeee;
  }
}
@media screen and (min-width: 1024px) {
  .swipeContent {
    background: transparent;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 140px);
    overflow: hidden;
    height: 56px;
    margin: 0px 11px;
    padding: 20px 0px;
  }
  .slideItem {
    font-weight: 400;
    font-size: 16px;
    color: #1b1b1b;
    margin-right: 20px;
    padding: 5px;
    user-select: none;
    cursor: pointer;
  }
  .activeSlideItem {
    border: 1px solid #144fa9;
    border-radius: 10px;
    width: max-content;
    color: #144fa9 !important;
  }
  .inActiveSlideItem {
    color: #b5b5b5;
    pointer-events: none;
  }
  .swipeBtn:hover .swipeArrow {
    background-color: #fafafa;
  }
  .swipeArrow {
    width: 14px;
    height: 26px;
    background-color: black;
    -webkit-mask: url("../../../assets/img/iconsSvg/swipeArrow.svg") no-repeat
      center;
    mask: url("../../../assets/img/iconsSvg/swipeArrow.svg") no-repeat center;
  }
  .noRouteTitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #1b1b1b;
    margin-bottom: 10px;
    margin-top: 40px;
  }
  .noRouteText {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #989898;
    margin-bottom: 0px;
  }
  .textStyle {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #eeeeee;
  }
}
</style>